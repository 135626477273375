import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, renderList as _renderList, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ds-panel ds-panel--space_2xl ds-panel--space_4xl-xl"
}
const _hoisted_2 = {
  key: 0,
  class: "ds-panel__element ds-panel__element--offset_top"
}
const _hoisted_3 = { class: "g-cell g-cols g-cols--12 g-cols--6-xl" }
const _hoisted_4 = {
  key: 0,
  class: "ds-panel__element ds-panel__element--offset_top"
}
const _hoisted_5 = { class: "ds-caption event-files-title" }
const _hoisted_6 = {
  key: 0,
  class: "ds-panel ds-panel--space_2xl ds-panel--space_3xl-xl"
}
const _hoisted_7 = { class: "ds-panel__element" }
const _hoisted_8 = { class: "ds-caption event-files-description" }
const _hoisted_9 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_10 = { class: "g-cell g-cols g-cols--12 g-cols--6-xl" }
const _hoisted_11 = { class: "title-count-wrapper" }
const _hoisted_12 = { class: "ds-caption event-files-title" }
const _hoisted_13 = { class: "question-counter" }
const _hoisted_14 = { class: "question-wrapper" }
const _hoisted_15 = { class: "question-text-content" }
const _hoisted_16 = { class: "question-text" }
const _hoisted_17 = { class: "answers-list" }
const _hoisted_18 = { class: "answer-item" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["value", "name", "id", "onUpdate:modelValue", "checked"]
const _hoisted_21 = { class: "d-control-radio__content" }
const _hoisted_22 = { class: "ds-panel ds-panel--space_xs" }
const _hoisted_23 = { class: "ds-panel__element" }
const _hoisted_24 = { class: "ds-caption answer-text" }
const _hoisted_25 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_26 = { class: "g-cell g-cols g-cols--12 g-cols--6-xl" }
const _hoisted_27 = { class: "test-result test-result--green" }
const _hoisted_28 = { class: "test-result__text" }
const _hoisted_29 = { class: "test-res answer-text" }
const _hoisted_30 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_31 = { class: "g-cell g-cols g-cols--12 g-cols--6-xl" }
const _hoisted_32 = { class: "test-result test-result--red" }
const _hoisted_33 = { class: "test-result__text" }
const _hoisted_34 = { class: "test-res answer-text" }
const _hoisted_35 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_36 = { class: "g-cell g-cols g-cols--12 g-cols--6-xl" }
const _hoisted_37 = {
  key: 2,
  class: "ds-panel__element ds-panel__element--offset_top"
}
const _hoisted_38 = { class: "ds-caption event-files-title" }
const _hoisted_39 = {
  key: 1,
  class: "ds-panel ds-panel--space_2xl ds-panel--space_4xl-xl"
}
const _hoisted_40 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_41 = { class: "ds-caption event-files-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_button = _resolveComponent("control-button")!
  const _component_tempate = _resolveComponent("tempate")!
  const _component_icon_test_passed = _resolveComponent("icon-test-passed")!
  const _component_icon_test_failed = _resolveComponent("icon-test-failed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
    ]), 2),
    ($setup.props.userHasEvent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ($setup.props.fileUrl && $setup.props.fileUrl!='None')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_control_button, {
                    class: "control-button--test",
                    tag: "a",
                    href: $setup.props.fileUrl,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.$t("Отримати сертифікат")), 1)
                    ]),
                    _: 1
                  }, 8, ["href"])
                ])
              ]))
            : ($setup.props.testId)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  ($setup.props.testTitle)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        ($setup.numberOfQuestion === 0)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("h2", _hoisted_5, _toDisplayString($setup.props.testTitle), 1),
                              ($setup.props.testDescription)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("div", _hoisted_8, _toDisplayString($setup.props.testDescription), 1)
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, [
                                  _createVNode(_component_control_button, {
                                    class: "control-button--test",
                                    tag: "DIV",
                                    onClick: _withModifiers($setup.startTest, ["prevent"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.$t("Почати тестування")), 1)
                                    ]),
                                    _: 1
                                  })
                                ])
                              ])
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("h2", _hoisted_12, _toDisplayString($setup.props.testTitle), 1),
                                _createElementVNode("div", _hoisted_13, _toDisplayString($setup.numberOfQuestion) + " / " + _toDisplayString($setup.countOfQuestions), 1)
                              ]),
                              (!$setup.testIsFinished)
                                ? (_openBlock(), _createBlock(_component_tempate, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_14, [
                                        _createElementVNode("div", _hoisted_15, [
                                          _createElementVNode("p", _hoisted_16, _toDisplayString($setup.currentQuestion.text), 1),
                                          _createElementVNode("div", _hoisted_17, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.currentQuestion.answers, (answer, answerIndex) => {
                                              return (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                                _createElementVNode("label", {
                                                  class: "d-control-radio d-control-radio--variant_flex test-radio",
                                                  onClick: ($event: any) => ($setup.addAnswer($setup.numberOfQuestion - 1, answer.id))
                                                }, [
                                                  _withDirectives(_createElementVNode("input", {
                                                    class: "d-control-radio__element",
                                                    type: "radio",
                                                    value: answer.id,
                                                    name: $setup.currentQuestion.id,
                                                    id: answer.id,
                                                    "onUpdate:modelValue": ($event: any) => (($setup.formdata.answer) = $event),
                                                    checked: $setup.formdata.answer == answer.id
                                                  }, null, 8, _hoisted_20), [
                                                    [_vModelRadio, $setup.formdata.answer]
                                                  ]),
                                                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "d-control-radio__label" }, null, -1)),
                                                  _createElementVNode("span", _hoisted_21, [
                                                    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "ds-caption ds-caption--size_sm" }, null, -1)),
                                                    _createElementVNode("span", _hoisted_22, [
                                                      _createElementVNode("span", _hoisted_23, [
                                                        _createElementVNode("p", _hoisted_24, _toDisplayString(answer.text), 1)
                                                      ])
                                                    ])
                                                  ])
                                                ], 8, _hoisted_19)
                                              ]))
                                            }), 256))
                                          ])
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_25, [
                                        _createElementVNode("div", _hoisted_26, [
                                          _createVNode(_component_control_button, {
                                            class: "control-button--test",
                                            tag: "DIV",
                                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.nextQuestion($setup.numberOfQuestion - 1)), ["prevent"]))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString($setup.nextBtnText), 1)
                                            ]),
                                            _: 1
                                          })
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_tempate, { key: 1 }, {
                                    default: _withCtx(() => [
                                      ($setup.testIsPassed)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createElementVNode("p", _hoisted_27, [
                                              _createElementVNode("span", _hoisted_28, _toDisplayString($setup.testResultText), 1),
                                              _createVNode(_component_icon_test_passed)
                                            ]),
                                            _createElementVNode("p", _hoisted_29, _toDisplayString($setup.$t("Ви склали іспит, тицяйте на кнопку та отримайте свій сертифікат!")), 1),
                                            _createElementVNode("div", _hoisted_30, [
                                              _createElementVNode("div", _hoisted_31, [
                                                _createVNode(_component_control_button, {
                                                  class: "control-button--variant_event_links",
                                                  tag: "a",
                                                  href: $setup.certFileUrl,
                                                  target: "_blank"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString($setup.$t("Отримати сертифікат")), 1)
                                                  ]),
                                                  _: 1
                                                }, 8, ["href"])
                                              ])
                                            ])
                                          ], 64))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createElementVNode("p", _hoisted_32, [
                                              _createElementVNode("span", _hoisted_33, _toDisplayString($setup.testResultText), 1),
                                              _createVNode(_component_icon_test_failed)
                                            ]),
                                            _createElementVNode("p", _hoisted_34, _toDisplayString($setup.$t("Ви не склали іспит, спробуйте ще раз!")), 1),
                                            _createElementVNode("div", _hoisted_35, [
                                              _createElementVNode("div", _hoisted_36, [
                                                _createVNode(_component_control_button, {
                                                  class: "control-button--variant_event_links",
                                                  tag: "DIV",
                                                  onClick: _withModifiers($setup.startTest, ["prevent"])
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString($setup.$t("Пройти тест ще раз")), 1)
                                                  ]),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ], 64))
                                    ]),
                                    _: 1
                                  }))
                            ], 64))
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                  _createElementVNode("p", _hoisted_38, _toDisplayString($setup.$t("Тестування не створено")), 1)
                ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_39, [
          _createElementVNode("div", _hoisted_40, [
            _createElementVNode("p", _hoisted_41, _toDisplayString($setup.$t("Необхідно зареєструватися на дану подію, щоб мати доступ до сертифікатів")), 1)
          ])
        ]))
  ], 64))
}