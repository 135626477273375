import { defineComponent as _defineComponent } from 'vue'
import { useShowModal } from '@/composables/useModalOpener'
import useGlobal from '@/composables/useGlobal'
import { ref, reactive, defineAsyncComponent, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnFiles',
  props: {
  linkToMaterials: {
    type: String,
    default: ""
  },
  userHasEvent: {
    type: Boolean,
    default: false
  },
  pastEvent: {
    type: Boolean,
    default: false
  },
  eventHasFiles: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
  // eventHasVideo: {
  //   type: Boolean,
  //   default: false
  // },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()

// onMounted(() => {
//   console.log('btn-files, mounted')
//   console.log(props)
// })

const goToMaterials = () => {
  console.log('goToMaterials')
  if (!props.userHasEvent || !props.eventHasFiles) {
    let message = {
      title: ''
    }
    if (!props.userHasEvent) {
      message = {
        title: $t('Необхідно авторизуватися та/або зареєструватися на подію чи її відеозапис'),
      }
    } else {
      message = {
        title: $t('Ще не додано жодного матеріалу'),
      }
    }
    
    useShowModal(message)
  } else {
    window.location = props.linkToMaterials
  }
}

const __returned__ = { props, $t, goToMaterials, get useShowModal() { return useShowModal }, get useGlobal() { return useGlobal }, ref, reactive, defineAsyncComponent, onMounted }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})