import { defineComponent as _defineComponent } from 'vue'
import { reactive } from 'vue'
import { useShowModal } from '@/composables/useModalOpener'
import useGlobal from '@/composables/useGlobal'
import { $vfm } from 'vue-final-modal'


export default /*@__PURE__*/_defineComponent({
  __name: 'RequestFormWrapper',
  props: {
  periods: {
    type: Array,
  },
  eventId: {
    type: Number,
  },
  price: {
    type: Number,
  },
  videoPrice: {
    type: Number,
  },
  actualPrice: {
    type: Number,
  },
  registrationCompleted: {
    type: Boolean,
  },
  pastEvent: {
    type: Boolean,
  },
  canBuyVideo: {
    type: Boolean,
  },
  userHasEvent: {
    type: Boolean,
  },
  cartType: {
    type: String,
  },
  registrationTime: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()
const formdata = reactive({
  period: {},
})

const selectDate = (period: any) => {
  formdata.period = period
}

const confirmRegistration = () => {
  console.log('confirmRegistration')
  // console.log('formdata.period', formdata.period)
  if (formdata.period.id || props.canBuyVideo) {
    $vfm.show({
      component: 'UiModalContainer',
    }, {
      classes: 'modal--size_lg',
      component: 'Events/RequestFormEvent',
      periodId: formdata.period.id,
      eventId: props.eventId,
    })
  } else {
    const message = {
      title: $t('Спочатку оберіть дату на яку бажаете записатись'),
    }

    useShowModal(message)
  }
}

const __returned__ = { props, $t, formdata, selectDate, confirmRegistration, reactive, get useShowModal() { return useShowModal }, get useGlobal() { return useGlobal }, get $vfm() { return $vfm } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})