import { defineComponent as _defineComponent } from 'vue'
import { useShowModal } from '@/composables/useModalOpener'
import useGlobal from '@/composables/useGlobal'
import { ref, reactive, defineAsyncComponent, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BtnCertificate',
  props: {
  linkToMaterials: {
    type: String,
    default: ""
  },
  userHasEvent: {
    type: Boolean,
    default: false
  },
  pastEvent: {
    type: Boolean,
    default: false
  },
  fileUrl: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  }
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()

// onMounted(() => {
//   console.log('btn-certificates, mounted')
//   console.log(props)
// })

const goToMaterials = () => {
  console.log('goToMaterials')
  window.location = props.linkToMaterials
}

const __returned__ = { props, $t, goToMaterials, get useShowModal() { return useShowModal }, get useGlobal() { return useGlobal }, ref, reactive, defineAsyncComponent, onMounted }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})