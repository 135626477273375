import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "event-btn-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_cert_get_btn = _resolveComponent("icon-cert-get-btn")!
  const _component_control_button = _resolveComponent("control-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.fileUrl && $props.fileUrl!='None')
      ? (_openBlock(), _createBlock(_component_control_button, {
          key: 0,
          class: "control-button--variant_event_links",
          tag: "a",
          href: $props.fileUrl,
          target: "_blank",
          disabled: $setup.props.disabled
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_icon_cert_get_btn)
          ]),
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.$t("Отримати сертифікат")), 1)
          ]),
          _: 1
        }, 8, ["href", "disabled"]))
      : (_openBlock(), _createBlock(_component_control_button, {
          key: 1,
          class: "control-button--variant_event_links",
          tag: "div",
          onClick: _withModifiers($setup.goToMaterials, ["prevent"]),
          disabled: $setup.props.disabled
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_icon_cert_get_btn)
          ]),
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.$t("Отримати сертифікат")), 1)
          ]),
          _: 1
        }, 8, ["disabled"]))
  ]))
}