import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_event_files_btn = _resolveComponent("icon-event-files-btn")!
  const _component_control_button = _resolveComponent("control-button")!

  return (_openBlock(), _createBlock(_component_control_button, {
    class: "control-button--variant_event_links",
    tag: "div",
    onClick: _withModifiers($setup.goToMaterials, ["prevent"]),
    disabled: $setup.props.disabled
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_icon_event_files_btn)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString($setup.$t("Додаткові матеріали")), 1)
    ]),
    _: 1
  }, 8, ["disabled"]))
}